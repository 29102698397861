import './public-path.js';

async function getAd(slot) {
	const response = await fetch(`/api/blog-ads-link?ad=${slot}`);
	const ad = await response.json();

	return ad;
}

function adOutput(ad) {
	return `${ad?.link ? `<a href="${ad.link}" target="_blank">` : ''}
		<img src="${ad.url}" alt="" width="100%" height="90" style="object-fit: contain; object-position: center;">
	${ad?.link ? `</a>` : ''}`;
}

function replaceAd(ad, id) {
	const output = adOutput(ad);
	const el = document.getElementById(id);

	el.insertAdjacentHTML('afterend', output);
	el.remove();
}

async function handle(slot, id) {
	const ad = await getAd(slot);

	if (!ad) {
		return;
	}

	replaceAd(ad, id);
}

const FinanchillBlogAds = {
	getAd,
	adOutput,
	replaceAd,
	handle,
}

window.FinanchillBlogAds = FinanchillBlogAds;
